<template>
    <div class="grid grid-cols-1 mb-4 ml-1">
        <div class="col-span-1 flex items-center mb-1">
            <div class=" text-xs mr-1 text-grey-2 font-bold inline">Status</div>
            <div class="uppercase font-semibold text-xs" :class="getStatusClass(value.status)">
                {{ getStatusTitle(value.status) }}
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {UserHelper} from '@/components/shared/mixins/userMixin'

export default {
    name: 'UserSummary',
    mixins: [UserHelper],
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    },
    methods: {},
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        })
    }
}
</script>
